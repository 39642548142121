import { motion } from "framer-motion";
import React from "react";
import { MenuToggle } from "./MenuToggle";
import { Link } from "gatsby";

interface ILayout {
  children: any;
  title: string;
}

export default function Layout({ children, title }: ILayout) {
  const [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const value = window.scrollY;
      // console.log(value);
      setScrollY(value);
    });
  }, []);

  return (
    <main>
      <title>{title}</title>
      <Header scrollY={scrollY} />
      {children}
      <Footer />
    </main>
  );
}
const variants = {
  open: { opacity: 1, right: "0vw", y: 0 },
  closed: { opacity: 0, right: "-100vw", y: 0 },
};

const navItems = [
  {
    id: 0,
    title: "서비스",
    href: "/services",
  },
  // {
  //   id: 1,
  //   title: "회사소개",
  //   href: "/services",
  // },
  {
    id: 2,
    title: "포트폴리오",
    href: "/portfolio",
  },
  {
    id: 3,
    title: "문의하기",
    href: "/cs",
  },
];

const Header = ({ scrollY }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  const headerActive = isActive || scrollY > 50;

  return (
    <header
      className={"basic-header " + (headerActive && "active")}
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
    >
      <div className="inner">
        {/* <img className="site-logo" src="" alt="logo" /> */}
        {/* <h1 className="site-logo">큐브린</h1> */}
        <img
          className="site-logo white"
          src="/img/cubelean_logo.png"
          alt="cubelean"
        />
        <img
          className="site-logo black"
          src="/img/cubelean_logo_black.png"
          alt="cubelean"
        />
        <MenuToggle toggle={() => setIsOpen(!isOpen)} />
        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          style={{ position: "fixed", top: 0 }}
        >
          <aside className="navs mobile">
            <li className="nav_headline"></li>
            {navItems.map((item, index) => {
              const variantsNaviItem = {
                open: {
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.3 + 0.2 * index },
                },
                closed: { opacity: 0, y: 100 },
              };
              return (
                <motion.li key={item.id} variants={variantsNaviItem}>
                  <Link to={item.href}>{item.title}</Link>
                </motion.li>
              );
            })}
          </aside>
        </motion.nav>

        <aside className="navs pc">
          <ul>
            {navItems.map((item, index) => {
              // const variantsNaviItem = {
              //   open: {
              //     opacity: 1,
              //     y: 0,
              //     transition: { delay: 0.3 + 0.2 * index },
              //   },
              //   closed: { opacity: 0, y: 100 },
              // };
              return (
                // <motion.li key={item.id} variants={variantsNaviItem}>
                <li>
                  <Link to={item.href}>{item.title}</Link>
                </li>
                // </motion.li>
              );
            })}
          </ul>
        </aside>
      </div>
    </header>
  );
};

const Footer = () => {
  return (
    <footer className="basic-footer">
      <div className="inner">
        <div className="footer-logo">큐브린</div>
        <div className="footer-policy">
          <Link to="/policy">개인정보처리방침</Link>
        </div>
        <p className="footer-info">
          사업자등록번호 : 229-03-05494 | 대표 : 문학주
        </p>
        <p className="footer-info">
          경기도 의정부시 시민로 146번길 30 | 010-8588-6380
        </p>
        <p className="footer-info">대표메일: cubelean00@gmail.com</p>
        <p className="footer-info copyright">
          Copyright 2022. Cubelean Co., Ltd., all right reserved
        </p>
      </div>
    </footer>
  );
};
