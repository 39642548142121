import { Checkbox, TextField } from "@material-ui/core";
import axios from "axios";
import { Form, Formik } from "formik";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { BACKEND_URL, serviceName } from "../config";
import { Encrypt } from "../encryption";
import { CheckEmail } from "../globalFunction";
import "../styles/global.scss";

//참고사이트 : atto.rn01.reconers.com/
// markup

const CsPage = () => {
  const [ok1, setOk1] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleChangeContent = (e) => {
    setContent(e.target.value);
  };
  const handleSubmit = (payload) => {
    if (!ok1) return alert("개인정보 수집 이용안내에 동의해주세요.");
    if (!username) return alert("성함을 입력해주세요.");
    if (!phone) return alert("연락처를 입력해주세요.");
    if (!email) return alert("이메일을 입력해주세요.");
    if (!CheckEmail(payload.email))
      return alert("이메일을 올바른형태로 입력해주세요.");

    if (!content) return alert("상담내용을 입력해주세요.");

    const reqData = {
      userId: "비회원",
      username: payload.username,
      phone: Encrypt(payload.phone),
      memo: content,
      email: payload.email,
      status: "상담대기",
    };

    axios.post(BACKEND_URL + "/cs/crm", reqData).then((res) => {
      if (res) {
        alert(
          `상담 신청이 완료되었습니다.\n영업일에 친절 상담원이\n빠르게 연락 드리겠습니다.\n감사합니다.`
        );
        window.location.replace("/");
      }
    });
  };
  return (
    <Layout title={`${serviceName} - 앱개발 문의하기`}>
      <Seo title="Home" />
      <section className="section sub-page-section">
        <img
          className="main-section-background"
          src="https://cdn.pixabay.com/photo/2018/03/17/10/49/bulletin-board-3233641_1280.jpg"
          alt="main"
        />
        <div className="main-filter"></div>
        <div className="inner">
          <article className="sub-page-slogun">
            <h1>문의하기</h1>
            <p>
              상담신청 정보를 입력해주시면, 입력해주신 연락처로 빠르게
              연락드리겠습니다.
            </p>
          </article>
        </div>
      </section>

      <div className="page-inner page-inner-padding">
        <div className="full-width">
          <div className="inputs">
            <div className="input_wrapper">
              <TextField
                placeholder="이름*"
                fullWidth
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <TextField
                placeholder="전화번호*"
                fullWidth
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <TextField
                placeholder="이메일*"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <textarea
            onChange={handleChangeContent}
            rows={5}
            style={{
              width: "100%",
              borderRadius: "5px",
              border: "1px solid lightgrey",
              padding: "15px",
              fontSize: "16px",
            }}
            placeholder="상담 내용*"
          >
            {content}
          </textarea>
          <div className="full-width" style={{ marginTop: 10 }}>
            <div className="gain">
              <Checkbox />
              개인정보 수집 이용안내
              <a className="view-detail">내용보기</a>
            </div>
          </div>
        </div>

        <button className="submit" onClick={handleSubmit}>
          상담신청하기
        </button>
      </div>
    </Layout>
  );
};

export default CsPage;
