import { motion, useTransform, useViewportScroll } from "framer-motion";
import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { serviceName } from "../config";
import "../styles/global.scss";
//참고사이트 : atto.rn01.reconers.com/
// import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "react-slick";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
import { Autoplay } from "swiper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// markup
const IndexPage = () => {
  return (
    <Layout title={serviceName}>
      <Seo title="Home" />
      <section className="section main-section">
        <div className="opening">
          <motion.div
            initial={{ width: 0 }}
            whileInView={{ width: "100%" }}
            viewport={{ once: true }}
            className="opening_div div1"
          ></motion.div>
          <motion.div
            initial={{ width: 0 }}
            whileInView={{ width: "100%" }}
            viewport={{ once: true }}
            transition={{ delay: 0.07, type: "spring" }}
            className="opening_div div2"
          ></motion.div>
          <motion.div
            initial={{ width: 0 }}
            whileInView={{ width: "100%" }}
            viewport={{ once: true }}
            transition={{ delay: 0.14, type: "spring" }}
            className="opening_div div3"
          ></motion.div>
        </div>
        {/* <img 
          className="main-section-background"
          src="https://cdn.pixabay.com/photo/2018/03/17/10/49/bulletin-board-3233641_1280.jpg"
          alt="main"
        /> */}
        {/* <div className="main-filter"></div> */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -100 },
          }}
          transition={{ delay: 0.3, type: "spring" }}
          className="main-slogun"
        >
          <h1>
            모바일 애플리케이션을 전문적으로 개발하고
            <br />
            당신의 비즈니스를 성공으로 안내합니다.
          </h1>
          {/* <p>아래로 스크롤해서 내용을 확인해보세용</p> */}
        </motion.div>
      </section>

      <section className="section info-section">
        <div className="inner">
          <h2 className="section-title mb">
            트렌디하고 다양한 앱 서비스를 만듭니다
          </h2>
          {/* <p className="section-ment"> 
            고객님과의 커뮤니케이션에 집중하며 최고의 사용자 경험과 디자인을
            <br className="no_mobile" />
            제안해 드리고, 높은 퀄리티의 앱을 함께 만들어가겠습니다.
          </p> */}

          {/* <article></article> */}
          <UiSwiper />
          <div className="contract-mini-box">
            <a className="contract-btn" href="/cs">
              문의하기
            </a>
          </div>
        </div>
      </section>
      {/* <section
        className="section info-section"
        style={{
          background: "#364864",
        }}
      >
        <div className="inner">
          <h2 className="section-title white title-margin">"큐브린" 에서는</h2>

          <div className="flex-box row space-between">
            <div className="smartphone_side">
              <ProductArticle
                title="고객 중심으로 LEAN한 개발"
                subTitle="빠르게 시장을 검증하고, 지속적인 피드백으로 개선해 나가며 서비스를 효과적으로 개발해 나갑니다"
                link="/"
              />
              <ProductArticle
                title="효율적인 방식 제안"
                subTitle="비슷한 기능을 구현하더라도 훨씬 빠르고 효율적인 방식이 있습니다. 개발비용을 절감할 수 있는 방법을 지속적으로 제안합니다"
                link="/"
              />
            </div>
            <div className="smartphone">
              <div className="smartphone_inner"></div>
            </div>
            <div className="smartphone_side">
              <ProductArticle
                title="고객의 만족이 곧 우리에게도 이익"
                subTitle="합리적인 비용으로 비즈니스를 빠르게 시작하실 수 있게 최선을 다하겠습니다"
                link="/"
              />
              <ProductArticle
                title="스마트한 유지보수"
                subTitle="앱 업데이트가 반영하는 시간조차 아까울 때가 있습니다. 앱의 변동 사항을 즉각적으로 반영하기 위해 관리자 페이지를 최대한 활용합니다"
                link="/"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section info-section">
        <div className="inner">
          <h2 className="section-title">PORTFOLIO</h2>
          <p className="section-ment">날개달린 작업물을 소개합니다.</p>
          <div className="flex-box row space-between">
            <ProductArticle
              title="아티클타이틀"
              subTitle="아티클 서브타이틀 내용입니다"
              link="/"
            />
            <ProductArticle
              title="아티클타이틀"
              subTitle="아티클 서브타이틀 내용입니다"
              link="/"
            />
            <ProductArticle
              title="아티클타이틀"
              subTitle="아티클 서브타이틀 내용입니다"
              link="/"
            />
            <ProductArticle
              title="아티클타이틀"
              subTitle="아티클 서브타이틀 내용입니다"
              link="/"
            />
          </div>
        </div>
      </section> */}

      <section
        className="section info-section"
        style={{ background: "#1a1a1a" }}
      >
        <div className="inner">
          <h2 className="section-title white">Q&A</h2>
          {/* <p className="section-ment white">날개달린 작업물을 소개합니다.</p> */}
          <div className="flex-box row">
            <Link to="/qna" className="qna-btn">
              앱의 수익 모델은 어떻게 구성하는게 좋을까요?
            </Link>
            <Link to="/qna" className="qna-btn">
              앱의 수익 모델은 어떻게 구성하는게 좋을까요?
            </Link>
            <Link to="/qna" className="qna-btn">
              앱의 수익 모델은 어떻게 구성하는게 좋을까요?
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const ProductArticle = ({ title, subTitle, link }) => {
  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      variants={{
        visible: { opacity: 1, y: -100 },
        hidden: { opacity: 0, y: 0 },
      }}
      transition={{ duration: 1 }}
      className="product-article"
    >
      <article className="article-icon">
        <div className="icon"></div>
      </article>
      <div className="article-info">
        <article className="article-title">{title}</article>
        <article className="article-sub-title">{subTitle}</article>
        {/* <article className="article-link">
          <Link to={link}>자세히보기</Link>
        </article> */}
      </div>
    </motion.article>
  );
};

const UiSwiper = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    delay: 0,
    pauseOnHover: false,
    responsive: [
      // 반응형 웹 구현 옵션
      {
        breakpoint: 960, //화면 사이즈 960px
        settings: {
          //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 768, //화면 사이즈 768px
        settings: {
          //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
          slidesToShow: 2.5,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div>
        <img
          className="ui-mockup"
          src="/img/mock.png"
          alt=""
          // style={{ width: "80%" }}
        />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
      <div>
        <img className="ui-mockup" src="/img/mock.png" alt="" />
      </div>
    </Slider>

    // <Swiper
    //   slidesPerView={3}
    //   spaceBetween={30}
    //   pagination={{
    //     clickable: true,
    //   }}
    //   loop={true}
    //   speed={5000}
    //   autoplay={{
    //     delay: 0,
    //     // time:1000,
    //     disableOnInteraction: false,
    //   }}
    //   modules={[Autoplay]}
    //   className="mySwiper"
    // >
    //   <SwiperSlide>Slide 1</SwiperSlide>
    //   <SwiperSlide>Slide 2</SwiperSlide>
    //   <SwiperSlide>Slide 3</SwiperSlide>
    //   <SwiperSlide>Slide 4</SwiperSlide>
    //   <SwiperSlide>Slide 5</SwiperSlide>
    //   <SwiperSlide>Slide 6</SwiperSlide>
    //   <SwiperSlide>Slide 7</SwiperSlide>
    //   <SwiperSlide>Slide 8</SwiperSlide>
    //   <SwiperSlide>Slide 9</SwiperSlide>
    // </Swiper>
  );
};

export default IndexPage;
