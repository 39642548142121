import axios from "axios";
import { BACKEND_URL } from "./config";

export const pattern_num = /[0-9]/; // 숫자 - pattern_num.test(String)
export const pattern_eng = /[a-zA-Z]/; // 문자 - pattern_eng.test(String)
export const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자 - pattern_spc.test(String)
export const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크 - pattern_kor.test(String)

export function telValidator(args) {
  if (/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(args)) {
    return true;
  }
  // alert(msg);
  return false;
}

export function regidentValidator(args) {
  if (/^[0-9]{10}/.test(args)) {
    return true;
  }
  // alert(msg);
  return false;
}

export function dateFormet(a: any) {
  var time = a; // 'hh:mm' 형태로 값이 들어온다
  var getTime = time.substring(0, 2); // 시간(hh)부분만 저장
  var intTime = parseInt(getTime); // int형으로 변환
  var str = "";
  var cvHour;
  if (intTime < 12) {
    // intTime이 12보다 작으면
    str = "오전 "; // '오전' 출력
  } else {
    // 12보다 크면
    str = "오후 "; // '오후 출력'
  } // intTime이 12면 변환 후 그대로 12
  if (intTime === 12) {
    cvHour = intTime;
  } // 나머지경우엔 intTime을 12로 나눈 나머지값이 변환 후 시간이 된다
  else {
    cvHour = intTime % 12;
  } // 'hh:mm'형태로 만들기
  var res = str + ("0" + cvHour).slice(-2) + time.slice(-3); // return
  return res;
}

// base64 URL을 넣으면 이미지서버에 업로드 후에 받아온 실제 이미지URL로 변경해준다. (S3용 아님)
export const base64ImageUpload = (dataURL: any, thumbnail?: boolean) => {
  return new Promise(function (resolve, reject) {
    axios
      // .post(MEDIA_SERVER_URL, {
      .post(BACKEND_URL + "/s3/upload", {
        data: dataURL,
        thumbnail,
      })
      .then((res: any) => {
        if (thumbnail) {
          resolve(res.data.thumbnail);
        } else {
          resolve(res.data.fileName);
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

// 에디터에 입력된 이미지들을 추출해서 AWS S3로 업로드 후에 치환해서 리턴
export const convertEditorUploadImages = async (content: string) => {
  let contentTemp;
  let changeContent = content;
  contentTemp = content;
  const images = contentTemp.match(
    /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/g
  );
  if (images) {
    if (images.length > 0) {
      for (var i = 0; i < images.length; i++) {
        const original = images[i];
        let generate = original.replace('<img src="', "");
        let base64ImageUrl = generate.replace('">', "");

        // base64일때만 실행
        if (base64ImageUrl.length > 10000) {
          // console.log('GEN', base64ImageUrl);
          const uploadedImgURL = await base64ImageUpload(base64ImageUrl);

          changeContent = contentTemp.replace(
            base64ImageUrl,
            uploadedImgURL.toString()
          );
        }
        contentTemp = changeContent;
      }
    }
  }

  return contentTemp;
};

export const fetcher = async (url: string) => {
  const resp = await axios.get(url);
  return resp.data;
};

export const promiseUpload = (data: any) => {
  return new Promise(function (resolve) {
    var reader = new FileReader();

    // // Base64 인코딩
    reader.readAsDataURL(data[0]);
    reader.onload = function () {
      const dataURL = reader.result;
      axios
        .post(BACKEND_URL + "/s3/upload", {
          data: dataURL,
          thumbnail: true,
        })
        .then((res: any) => {
          resolve(res.data);
        });
    };
  });
};

export const promiseUploadBase64 = (base64: any) => {
  return new Promise(function (resolve) {
    axios
      .post(BACKEND_URL + "/s3/upload", {
        data: base64,
        thumbnail: true,
      })
      .then((res: any) => {
        resolve(res.data);
      });
  });
};

export function numberWithCommas(x) {
  return String(Math.floor(x)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function stringToArray(str: string) {
  return str.split("|");
}

export function arrayToString(arr) {
  let result = "";
  arr.map((item) => item + "|");
  for (var i = 0; i < arr.length; i++) {
    result += arr[i];

    if (i === arr.length - 1) {
      result += "|";
    }
  }

  return result;
}

export function CheckEmail(str: string) {
  var reg_email =
    /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

  if (!reg_email.test(str)) {
    return false;
  } else {
    return true;
  }
}

export function levelToGrade(level: number) {
  switch (level) {
    case 1:
      return "일반고객";
    case 2:
      return "즉시구매고객";
    case 3:
      return "렌탈고객";
    case 4:
      return "파트너";
    case 5:
      return "협력사";

    default:
      return "일반고객";
  }
}

export const stringToHtml = (str: string) => {
  if (!str) return;
  const material = JSON.stringify(str);

  return JSON.parse(material.replace("\\n", "<br/>"));
};
